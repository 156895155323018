import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // React Router の Link をインポート
import top_interview1 from '../img/top_interview1.png';  // 画像1
import top_interview2 from '../img/top_interview2.png';  // 画像2
import arrow from '../img/arrow.svg';

// 画像とリンクのペアを定義
const items = [
  { image: top_interview2, link: '/interview/2' },
  { image: top_interview1, link: '/interview/1' }
  // ここに追加の画像を追加できます
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 715);

  // 画面サイズを監視し、モバイル表示かどうかを判定
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 715);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 次の画像に移動
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  // 前の画像に移動
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  // サブ画像のインデックスを取得
  const getNextIndex = () => (currentIndex === items.length - 1 ? 0 : currentIndex + 1);

  return (
    <div>
      <h2 style={{ 
        fontFamily: "Noto Sans JP",
        fontSize: "25px",
        fontWeight: "700",
        color: "rgba(41,41,41,1)",
        textAlign: "center",
        display: "block",
        direction: "column",
        shrink: "0",
        position: "relative",
        padding: "0px 0px 20px 0px",
        whiteSpace: "pre-wrap"
      }}>インタビュー</h2>
      <div style={{ 
        position: 'relative', 
        width: '100%', 
        margin: '0px auto 60px auto', 
        overflow: 'hidden', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: isMobile ? '0px' : '0px 60px'  // スマホ版ではpaddingを0にする
      }}>
        {/* 2個以上のアイテムがある場合のみ矢印を表示 */}
        {(isMobile || items.length > 2) && (
          <button
            onClick={handleNextClick}
            style={{
              position: 'absolute',
              top: '50%',
              left: isMobile ? '10px' : '40px',
              transform: 'translateY(-50%)',
              background: 'rgba(0, 0, 0, 0)',
              border: 'none',
              cursor: 'pointer',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={arrow}
              alt="矢印"
              style={{
                width: isMobile ? '30px' : '40px',
                height: isMobile ? '30px' : '40px',
                transform: 'rotate(180deg)', // 180度回転
              }}
            />
          </button>

        )}

        {/* メイン画像 */}
        <div
          style={{
            width: isMobile ? '80%' : '50%',  // スマホ版では80%、PC版では50%
            transition: 'all 0.5s ease',
            margin: '0 5px 0 10px',
            textAlign: 'center',
          }}
        >
          <Link to={items[currentIndex].link}>  {/* メイン画像にリンクを設定 */}
            <img
              src={items[currentIndex].image}
              alt="Main Slide"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '5px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              }}
            />
          </Link>
        </div>

        {/* 右のサブ画像 */}
        {!isMobile && (
          <div
            style={{
              width: '50%',  // PC版では右のサブ画像もメイン画像と同じ幅にする
              transition: 'all 0.5s ease',
              margin: '0 10px 0 5px',
            }}
          >
            <Link to={items[getNextIndex()].link}>  {/* 右のサブ画像にリンクを設定 */}
              <img
                src={items[getNextIndex()].image}
                alt="Next Slide"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                }}
              />
            </Link>
          </div>
        )}

        {/* 2個以上のアイテムがある場合のみ矢印を表示 */}
        {(isMobile || items.length > 2) && (
          <button
            onClick={handleNextClick}
            style={{
              position: 'absolute',
              top: '50%',
              right: isMobile ? '10px' : '40px',
              transform: 'translateY(-50%)',
              background: 'rgba(0, 0, 0, 0)',
              border: 'none',
              cursor: 'pointer',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={arrow}
              alt="矢印"
              style={{
                width: isMobile ? '30px' : '40px',
                height: isMobile ? '30px' : '40px',
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
